import pages from '@/features/pages';

const bindPhone = () => import('@/views/myInfo/bindPhone.vue'); //绑定手机
const myVideos = () => import('@/views/myVideos/myVideos.vue'); //我的视频

const myIncome = () => import('@/views/myIncome/myIncome.vue'); //加油站
const incomeList = () => import('@/views/myIncome/incomeList.vue'); //收益列表
const cashNow = () => import('@/views/myIncome/cashNow.vue'); //提现

const job = () => import('@/views/tools/job'); //招聘首页
const detail = () => import('@/views/tools/job/detail.vue'); //招聘详情
const edit = () => import('@/views/tools/job/edit.vue'); //招聘编辑
const myJob = () => import('@/views/tools/job/myJob.vue'); //我的招聘

export default [
  {
    path: '/',
    redirect: 'home',
    name: 'index',
    component: () => import('@/views/index.vue'), //主tabbar
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/homeIndex'),
        meta: {
          // index: 0,
        },
      },
      {
        path: 'myVideos',
        name: 'myVideos',
        component: () => import('@/views/my/lessons.vue'),
        // component: myVideos,
        meta: {
          // index: 0.1,
        },
      },
      {
        path: '/prod',
        name: 'prod',
        component: () => import('@/views/products/lists.vue'),
        meta: {
          // userType: [2],
        },
      },
      {
        path: 'myInfo',
        name: 'myInfo',
        component: () => import('@/views/myInfo/myInfo.vue'),
        meta: {
          // index: 0.4,
        },
      },
      {
        //兼容老链接
        path: 'c_profit',
        redirect: '/myIncome',
      },
      {
        path: 'myIncome',
        name: 'myIncome',
        component: myIncome,
        meta: {
          // index: 0.2,
          userType: [2],
        },
      },
      {
        path: 'tools',
        name: 'tools',
        component: () => import('@/views/tools/tools.vue'),
        meta: {
          // index: 0.3,
          userType: [2],
        },
      },
    ],
  },
  {
    path: '/citys',
    name: 'citys',
    component: () => import('@/views/home/citys.vue'),
    meta: {
      // index: 1,
    },
  },
  {
    path: '/collect/add',
    component: () => import('@/views/collect/add.vue'),
    userType: [2],
  },
  {
    path: '/collect',
    component: () => import('@/views/collect/index.vue'),
    userType: [2],
  },
  {
    path: '/wish',
    name: 'wish',
    component: () => import('@/views/home/wish.vue'),
    meta: {
      title: '许愿',
      // index: 3,
      userType: [1],
      // isShare: 1,
    },
  },
  {
    path: '/lessonInfo',
    name: 'lessonInfo',
    component: () => import('@/views/home/lessonInfo'),
    meta: {
      // index: 2,
    },
  },
  //兼容老链接
  {
    path: '/c_details/:id',
    redirect: (to) => `/lessonInfo?id=${to.params.id}`,
  },
  {
    //兼容老链接
    path: '/Video/:id/:lid?',
    redirect: (to) => `/lessonInfo?id=${to.params.id}`,
  },
  {
    path: '/line',
    name: 'line',
    component: myVideos,
    meta: {
      // index: 1,
      userType: [1],
    },
  },
  {
    path: '/coaLine',
    name: 'coaLine',
    component: myVideos,
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/c_LinelistSwitchCoach',
    name: 'LinelistSwitchCoach',
    component: () => import('@/views/myVideos/LinelistSwitchCoach.vue'),
    meta: {
      title: '线路图教练切换',
    },
  },
  {
    path: '/addField',
    name: 'addField',
    component: myVideos,
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/coaVideos',
    name: 'coaVideos',
    component: myVideos,
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/myOrders',
    name: 'myOrders',
    component: () => import('@/views/myInfo/myOrders.vue'),
    meta: {
      // index: 1,
    },
  },
  {
    path: '/personCert',
    name: 'personCert',
    component: () => import('@/views/myInfo/personCert.vue'),
    meta: {
      // index: 1,
    },
  },
  {
    path: '/operatProposal',
    name: 'operatProposal',
    component: () => import('@/views/myInfo/operatProposal.vue'),
    meta: {
      // index: 1,
    },
  },
  {
    path: '/talenRrecruitment',
    name: 'talenRrecruitment',
    component: () => import('@/views/myInfo/talenRrecruitment.vue'),
    meta: {
      // index: 1,
    },
  },
  {
    path: '/talentDetail',
    name: 'talentDetail',
    component: () => import('@/views/myInfo/talentDetail.vue'),
    meta: {
      // index: 1,
    },
  },
  {
    path: '/bindPhone',
    name: 'bindPhone',
    component: bindPhone,
    meta: {
      // index: 2,
    },
  },
  {
    //邀请教练入口
    path: '/c_bind',
    name: 'c_bind',
    component: bindPhone,
    meta: {
      // index: 2,
    },
  },
  {
    //兼容老链接
    path: '/c_backregister',
    redirect: `/c_bind`,
  },
  {
    path: '/downLine/:id/:cate?/:q_type?/:cate_id?',
    name: 'downLine',
    component: () => import('@/views/myVideos/downLine.vue'),
    meta: {
      // index: 2,
      // isShare: 1,
    },
  },
  {
    path: '/Code',
    name: 'code',
    component: () => import('@/views/code.vue'),
    meta: {
      // index: 1,
    },
  },
  {
    path: '/myQrs',
    name: 'myQrs',
    component: () => import('@/views/myInfo/myQrs.vue'),
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/qrDetail',
    name: 'qrDetail',
    component: () => import('@/views/myInfo/qrDetail.vue'),
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('@/views/home/share'),
    meta: {
      // index: 10,
      // isShare: 1,
      userType: [2],
    },
  },
  //区代
  {
    path: '/agent',
    name: 'agent',
    component: myIncome,
    meta: {
      // index: 0,
      isAgent: 1,
    },
  },
  {
    path: '/todayIncome',
    name: 'todayIncome',
    component: incomeList,
    meta: {
      title: '今日收益',
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/agentTodayIncome',
    name: 'agentTodayIncome',
    component: incomeList,
    meta: {
      // index: 1,
      isAgent: 1,
    },
  },
  {
    path: '/totalIncome',
    name: 'totalIncome',
    component: incomeList,
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/agentTotalIncome',
    name: 'agentTotalIncome',
    component: incomeList,
    meta: {
      // index: 1,
      isAgent: 1,
    },
  },
  {
    path: '/cashIncome',
    name: 'cashIncome',
    component: incomeList,
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/agentCashIncome',
    name: 'agentCashIncome',
    component: incomeList,
    meta: {
      // index: 2,
      isAgent: 1,
    },
  },

  {
    path: '/cashNow',
    name: 'cashNow',
    component: cashNow,
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/agentCashNow',
    name: 'agentCashNow',
    component: cashNow,
    meta: {
      // index: 1,
      isAgent: 1,
    },
  },
  {
    path: '/agentCheck',
    name: 'agentCheck',
    component: () => import('@/views/myIncome/agentCheck.vue'),
    meta: {
      // index: 1,
      isAgent: 1,
    },
  },
  //兼容旧链接
  {
    path: '/e_examine',
    redirect: `/agentCheck`,
  },
  {
    path: '/eStatistics',
    name: 'eStatistics',
    component: () => import('@/views/myIncome/eStatistics.vue'),
    meta: {
      // index: 1,
      isAgent: 1,
    },
  },
  {
    path: '/eText',
    name: 'eText',
    component: () => import('@/views/myIncome/eText.vue'),
    meta: {
      // index: 1,
      isAgent: 1,
    },
  },
  {
    path: '/editCoaInfo',
    name: 'editCoaInfo',
    component: () => import('@/views/tools/editCoaInfo.vue'),
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/editAward',
    name: 'editAward',
    component: () => import('@/views/tools/editAward.vue'),
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/c_avatarType',
    name: 'avatarType',
    component: () => import('@/views/tools/coach/avatarType'),
    meta: {
      title: '招生头像',
      userType: [2],
    },
  },
  {
    path: '/c_templist',
    name: 'tempList',
    component: () => import('@/views/tools/coach/tempList'),
    meta: {
      title: '招生头像',
      userType: [2],
    },
  },
  {
    path: '/c_avatarmaking',
    name: 'avatarMaking',
    component: () => import('@/views/tools/coach/avatarMaking'),
    meta: {
      title: '招生头像',
      userType: [2],
    },
  },
  {
    path: '/c_makingcom',
    name: 'makingCom',
    component: () => import('@/views/tools/coach/makingCom'),
    meta: {
      title: '招生头像',
      userType: [2],
    },
  },
  {
    path: '/job',
    name: 'job',
    component: job,
    meta: {
      // index: 1,
    },
  },
  {
    path: '/car',
    name: 'car',
    component: job,
    meta: {
      // index: 1,
    },
  },
  {
    path: '/train',
    name: 'train',
    component: job,
    meta: {
      // index: 1,
    },
  },
  {
    path: '/myjob',
    name: 'myJob',
    component: myJob,

    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/mycar',
    name: 'myCar',
    component: myJob,
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/mytrain',
    name: 'myTrain',
    component: myJob,
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/carEdit',
    name: 'carEdit',
    component: edit,
    meta: {
      // index: 3,
      userType: [2],
    },
  },
  {
    path: '/jobEdit',
    name: 'jobEdit',
    component: edit,
    meta: {
      // index: 3,
      userType: [2],
    },
  },
  {
    path: '/trainEdit',
    name: 'trainEdit',
    component: edit,
    meta: {
      // index: 3,
      userType: [2],
    },
  },

  {
    path: '/jobDetail',
    name: 'jobDetail',
    component: detail,
    meta: {
      // index: 3,
    },
  },
  {
    //学员可以看车辆详情
    path: '/carDetail',
    name: 'carDetail',
    component: detail,
    meta: {
      // index: 3,
    },
  },
  {
    path: '/trainDetail',
    name: 'trainDetail',
    component: detail,
    meta: {
      // index: 3,
    },
  },
  {
    //兼容老链接
    path: '/c_recruitdetails',
    redirect: '/jobDetail',
  },
  {
    //学员可以看车辆详情 //兼容老链接
    path: '/c_makedetails',
    redirect: '/carDetail',
  },
  {
    //兼容老链接
    path: '/c_traindetails',
    redirect: '/trainDetail',
  },
  {
    path: '/pushSet',
    name: 'pushSet',
    component: () => import('@/views/myInfo/pushSet.vue'),
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/matter',
    name: 'matter',
    component: () => import('@/views/myInfo/matter.vue'),
    meta: {
      userType: [2],
    },
  },
  {
    path: '/prod_detail/:id',
    name: 'prodDetail',
    component: () => import('@/views/products/detail.vue'),
    meta: {
      // userType: [2],
    },
  },
  {
    path: '/prod_info/:order_sn',
    name: 'prodInfo',
    component: () => import('@/views/products/info.vue'),
    meta: {
      // userType: [2],
    },
  },
  {
    path: '/prod_kf/:order_sn',
    name: 'prodKf',
    component: () => import('@/views/products/kf.vue'),
    meta: {
      // userType: [2],
    },
  },
  {
    path: '/prod_order/:order_sn',
    name: 'prodOrder',
    component: () => import('@/views/products/order.vue'),
    meta: {
      // userType: [2],
    },
  },
  {
    path: '/prod_oLst',
    name: 'prodOLst',
    component: () => import('@/views/products/olist.vue'),
    meta: {
      // userType: [2],
    },
  },
  {
    path: '/integral',
    name: 'integral',
    component: () => import('@/views/integral'),
    meta: {
      // index: 1,npm
      userType: [2],
    },
  },
  {
    path: '/myIntegral',
    name: 'myIntegral',
    component: () => import('@/views/integral/myIntegral'),
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/itgHistory',
    name: 'itgHistory',
    component: () => import('@/views/integral/itgHistory'),
    meta: {
      // index: 3,
      userType: [2],
    },
  },

  {
    path: '/itgOrderDetail',
    name: 'itgOrderDetail',
    component: () => import('@/views/integral/itgOrderDetail'),
    meta: {
      // index: 4,
      userType: [2],
    },
  },
  {
    //兼容老链接
    path: '/c_active',
    redirect: '/itgOrderDetail',
  },
  {
    path: '/itgDetail',
    name: 'itgDetail',
    component: () => import('@/views/integral/itgDetail'),
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/integral/address'),
    meta: {
      // index: 3,
      userType: [2],
    },
  },
  {
    path: '/emsDetail',
    name: 'emsDetail',
    component: () => import('@/views/tools/ems/detail'),
    meta: {
      userType: [2],
    },
  },
  {
    path: '/emsLst',
    name: 'emsLst',
    component: () => import('@/views/tools/ems/lists'),
    meta: {
      userType: [2],
    },
  },
  {
    path: '/dispatch',
    name: 'dispatch',
    component: () => import('@/views/dispatch'),
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    path: '/dphDetail',
    name: 'dphDetail',
    component: () => import('@/views/dispatch/dphDetail'),
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    //兼容老链接
    path: '/c_give',
    redirect: '/dphDetail',
  },
  {
    path: '/complain',
    name: 'complain',
    component: () => import('@/views/complain'),
    meta: {
      // index: 1,
      userType: [2],
    },
  },
  {
    //兼容老链接
    path: '/complain/index',
    redirect: '/complain',
  },
  {
    //兼容老链接
    path: '/complain/details',
    redirect: '/cpnDetail',
  },
  {
    path: '/cpnDetail',
    name: 'cpnDetail',
    component: () => import('@/views/complain/cpnDetail'),
    meta: {
      // index: 2,
      userType: [2],
    },
  },
  {
    path: '/carGroup',
    name: 'carGroup',
    component: () => import('@/views/myInfo/carGroup.vue'),
    meta: {
      userType: [2],
    },
  },
  {
    path: '/ad',
    name: 'ad',
    component: () => import('@/views/myInfo/ad.vue'),
    meta: {
      userType: [2],
    },
  },
  {
    path: '/myinfo/permit',
    name: 'permit',
    component: () => import('@/views/myInfo/permit'),
    meta: {
      // index: 2
    },
  },
  {
    path: '/billPush',
    name: 'billPush',
    component: () => import('@/views/myInfo/billPush'),
    meta: {
      // index: 2
    },
  },
  {
    path: '/punchIn',
    name: 'punchIn',
    component: () => import('@/views/myInfo/punchIn'),
    meta: {
      // index: 2
    },
  },
  {
    path: '/spreadSetting',
    name: 'spreadSetting',
    component: () => import('@/views/myInfo/spreadSetting'),
    meta: {
      // index: 2
    },
  },
  {
    path: '/spreadLst',
    name: 'spreadLst',
    component: () => import('@/views/myInfo/spreadLst'),
    meta: {
      // index: 2
    },
  },

  // 兼容旧前端前端
  {
    // 积分商品详情
    path: '/c_goods',
    redirect: '/itgDetail',
  },

  ...pages,
  {
    path: '/:catchAll*',
    redirect: '/',
  },
];
